var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { attrs: { id: "select-business-component" } }, [
        !_vm.hasAccountCompanies
          ? _c("section", { staticClass: "get-started-container" }, [
              _c("h2", [_vm._v("\n      Welcome to Stageline\n    ")]),
              _c("p", { staticClass: "get-started-text" }, [
                _vm._v(
                  "\n      Your intuitive guide through every stage of your business.\n      Whether you’re in the idea stage, ready to form your business, or already operating,\n      the first step is the easiest—adding your company to your account.\n      From there, chart your own course, your way!\n    "
                ),
              ]),
              _c(
                "button",
                {
                  staticClass: "primary small",
                  attrs: { "aria-label": "get started" },
                  on: { click: _vm.startNewBusiness },
                },
                [_vm._v("\n      Get Started\n    ")]
              ),
            ])
          : _c("div", { staticClass: "get-started-container" }, [
              _c("h2", [
                _vm._v(
                  "\n      Use Stageline to manage your " +
                    _vm._s(_vm.isEntityTypeDomains ? "domains" : "business") +
                    ".\n    "
                ),
              ]),
              _c("div", { staticClass: "companies-list-container" }, [
                _c(
                  "div",
                  { staticClass: "select-company-options-container" },
                  [
                    _c("company-selector", {
                      attrs: { companies: _vm.accountCompanies },
                      on: { "company-selected": _vm.companySelected },
                    }),
                    _vm.displayUpdateAvailable
                      ? _c(
                          "div",
                          { staticClass: "update-available-container" },
                          [
                            _c("feather-icon", {
                              staticClass: "alert-circle",
                              attrs: { type: "alert-circle" },
                            }),
                            _c("p", { staticClass: "alert-text" }, [
                              _vm._v(
                                "\n            One of your businesses has an update available\n          "
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("hr", { staticClass: "divider" }),
                    _c(
                      "div",
                      { staticClass: "buttons-container" },
                      [
                        !_vm.isEntityTypeDomains
                          ? _c(
                              "button",
                              {
                                staticClass: "primary large",
                                attrs: { "aria-label": "add a business" },
                                on: { click: _vm.startNewBusiness },
                              },
                              [
                                _vm._v(
                                  "\n            Add a business to my account\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "router-link",
                          {
                            staticClass: "link-home",
                            attrs: { to: "/dashpanel" },
                          },
                          [
                            _vm._v(
                              "\n            Go to my account homepage\n            "
                            ),
                            _c("fa-icon", {
                              staticClass: "fa-sm pl-1 arrow",
                              attrs: { icon: "chevron-right" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
      ])
    : _c(
        "div",
        { staticClass: "spinner-container" },
        [_c("ct-centered-spinner", { attrs: { variant: "primary" } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }